<template>
  <div class="dialog_box">
    <el-dialog :visible.sync="show" title="Are you sure you want to cancel?" >
      <div class="select_radio">
        <el-radio-group v-model="notification">
          <el-radio :label="true">Cancel lease and send notification to Tenant and Owner</el-radio>
          <el-radio :label="false">Cancel lease but DO NOT send notification emails</el-radio>
          <el-radio :label="3">Cancel and Re-create Lease</el-radio>
        </el-radio-group>
      </div>
      <div class="dialog-footer">
        <el-button @click="show = false"  >Exit without Cancelling Lease</el-button>
        <el-button class="primary-button" @click="cancel" :loading="loading" >Cancel Lease</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiDeleteLease, apiCreateLease } from "../../../API/api";
export default {
  data() {
    return {
      show: false,
      notification: true, //取消租约时通知游客和房主
      loading: false,
    };
  },
  methods: {
    // 取消租约
    cancel() {
      this.loading = true;
      if (this.notification === 3) {
        apiDeleteLease(this.$route.params.id)
          .then((res) => {
            let obj = Object.assign({},this.$store.state.lease);
            delete obj.status;
            delete obj.lease_id;
            delete obj.sign_info;
            delete obj.sign_file_info;
            delete obj.security_deposit
            this.$emit("createNewLease",obj)
            //   .then((res) => {
            //     this.$emit("refresh",res.lease_id )
            //   }).catch((err) => {
            //     if (err.response.data.detail) {
            //       this.$message.error(err.response.data.detail);
            //     } else {
            //       this.$message.error("fail.");
            //     }
            //   }).finally(() => {
            //     this.loading = false;
            //     this.show = false;
            //   })

          }).catch((err) => {
            this.$message.error("Cancel lease fail");
          }) .finally(() => {
            this.loading = false;
            this.show = false;
          });
      } else {
        apiDeleteLease(this.$route.params.id, {
          is_send_email: this.notification,
        })
          .then((res) => {
            this.$message.success("Cancel lease successful");
            this.$router.push({ name: "leases" });
          })
          .catch((err) => {
            this.$message.error("Cancel lease fail");
          })
          .finally(() => {
            this.loading = false;
            this.show = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
.dialog_box {
  /* 取消对话框 */
  .select_radio {
    text-align: left;
    /deep/ .el-radio {
      display: block;
      text-align: left;
      margin-bottom: 30px;
    }
    /deep/ .el-radio__inner {
      width: 20px;
      height: 20px;
      &::after {
        width: 0;
      }
    }
    /deep/ .el-radio__input.is-checked + .el-radio__label {
      color: #333333;
      font-family: "Roboto-Regular", sans-serif;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      background-image: url("../../../assets/img/true.png");
      background-size: cover;
    }
  }
  /deep/ .el-dialog{
    width: 450px;
   .el-dialog__header {
    text-align: left;
    height: 0.535714rem;
  }
  .el-dialog__header .el-dialog__title,
   .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
    color: #36425d;
    font-family: "Roboto-Bold", sans-serif;
  }
  .el-button{margin-bottom: 10px;}
    @media (max-width:992px) {
      width: 90%;
    }
  }
}
</style>
<template>
  <div class="lease-detail" v-loading.fullscreen.lock="fullscreenLoading">
    <div style="padding: 0 20px 0 20px">
      <div class="detail-top">
        <p class="detail-top-info">Lease Details</p>
      </div>
      <div class="lease-form">
        <el-form :model="data" :rules="rules" ref="leaseForm" label-position="left">
          <div class="row broke-row">
            <div class="broke-sheck">
              <el-checkbox :disabled="data.status && data.status != 'Draft'" v-model="data.is_co_broke_lease" style="margin-bottom: 10px" >Co - Broke Lease</el-checkbox>
              <div>
                <el-radio-group class="slsect-radio" v-model="data.represent" v-if="data.is_co_broke_lease === true" :disabled="data.status && data.status != 'Draft'">
                  <el-radio label="owner" style="margin-bottom: 10px">Representing Owner</el-radio>
                  <el-radio label="tenant" style="margin-bottom: 10px">Representing Tenant</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="broke-input" v-if="data.is_co_broke_lease === true">
              <el-form-item prop="co_broke_agency" :rules="rules.required">
                <div class="row input-row">
                  <span class="broke-text">Co-Broke Agency</span>
                  <el-select :disabled="data.status && data.status != 'Draft'" v-model="data.co_broke_agency" placeholder="Please choose" filterable default-first-option>
                    <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.name"></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="row infor-row">
            <div class="form-right">
              <el-form-item label="Property Address" class="move" ref="propertyAddress" prop="listing_address" :rules="rules.required">
                <el-input v-if="data.is_co_broke_lease && data.represent === 'tenant'" :disabled="data.status && data.status != 'Draft'"  v-model="data.listing_address" />
                <el-input v-else readonly v-model="data.listing_address" placeholder="Choose Property" :disabled="data.status && data.status != 'Draft'" :max="100" :min="0"
                  @click.native="data.status && data.status != 'Draft' ? ($refs.propertiesDialog.show = false): ($refs.propertiesDialog.show = true)">
                  </el-input>
              </el-form-item>
              <el-form-item label="Tenant Name" ref="tenantName"  prop="tenant_name" :rules="rules.required"  >
                <el-input readonly v-model="data.tenant_name" placeholder="Choose Tenant" style="cursor: pointer" :disabled="data.status && data.status != 'Draft'" @click.native="openContactDialog"
                ></el-input>
              </el-form-item>
              <!--  stephen@congdonandcoleman.com-->
              <el-form-item
                v-if=" ($store.state.userInfo.is_admin === true)&& $store.state.userInfo.user_id != '10005' "
                ref="user" prop="agent_name" :rules="rules.required" label="Leasing Agent" >
                <el-input readonly v-model="data.agent_name" placeholder="Choose Tenant" :disabled="data.status && data.status != 'Draft'"
                  @click.native=" data.status && data.status != 'Draft'  ? ($refs.showAgentDialog.show = false) : ($refs.showAgentDialog.show = true) "
                ></el-input>
              </el-form-item>
              <div class="row date-item move">
                <el-form-item label="Arrival Date" prop="arrival_date" :rules="rules.required">
                  <el-date-picker style="width: 100%" :disabled="data.status && data.status != 'Draft'&&data.is_block_date" :picker-options="pickerOptions0" v-model="data.arrival_date" :default-value="mouthdate.start_date"  type="date" format="MM/dd/yyyy" value-format="yyyy-MM-dd" >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Departure Date" prop="departure_date" :rules="rules.required">
                  <el-date-picker style="width: 100%" :disabled="data.status && data.status != 'Draft'&&data.is_block_date" :picker-options="pickerOptions1" v-model="data.departure_date" :default-value="mouthdate.end_date"  type="date" format="MM/dd/yyyy" value-format="yyyy-MM-dd" >
                  </el-date-picker>
                </el-form-item>
              </div>
                <!-- <el-date-picker style="width: 100%" v-model="data.dates" type="daterange" range-separator="—" start-placeholder="Arrival Date" end-placeholder="Departure Date"
                  :picker-options="expireTimeOption" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :disabled="data.status && data.status != 'Draft'&&data.is_block_date">
                </el-date-picker> -->
              <!-- </el-form-item> -->
              <div class="row date-item move" >
                <div class="col-12">
                <el-form-item label="Check-in Time" prop="checkin_time" :rules="rules.required">
                  <el-time-picker value-format="HH:mm:ss" format="h:mm:A" v-model="data.checkin_time" placeholder="" :disabled="data.status && data.status != 'Draft'">
                  </el-time-picker>
                </el-form-item>
                </div>
                <div class="col-12">
                <el-form-item label="Check-out Time" prop="checkout_time" :rules="rules.required">
                  <el-time-picker value-format="HH:mm:ss" format="h:mm:A" v-model="data.checkout_time" placeholder="" :disabled="data.status && data.status != 'Draft'">
                  </el-time-picker>
                </el-form-item>
                </div>
              </div>
              <el-form-item label="Add standard clauses">
                <el-checkbox-group class="check-group-button" v-model="data.clauses" :disabled="data.status && data.status != 'Draft'" @change="clausesChange" >
                  <el-checkbox-button v-for="city in clauses" :label="city" :key="city" >{{ city }}</el-checkbox-button >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item class="form-item-textarea" label="Additional provisions" >
                <el-input type="textarea" :autosize="{ minRows: 5 }" resize="none" v-model="data.comment" :disabled="data.status && data.status != 'Draft'" ></el-input>
                <p class="textarea-tip"> This text will be printed on the signature page. </p>
              </el-form-item>
            </div>
            <div class="form-left">
              <el-form-item label="Property Address" class="pc" ref="propertyAddress" prop="listing_address" :rules="rules.required">
                <el-input v-if="data.is_co_broke_lease && data.represent === 'tenant'" v-model="data.listing_address" />
                <el-input v-else readonly v-model="data.listing_address" placeholder="Choose Property" :disabled="data.status && data.status != 'Draft'" :max="100" :min="0"
                  @click.native="data.status && data.status != 'Draft' ? ($refs.propertiesDialog.show = false): ($refs.propertiesDialog.show = true)">
                  </el-input>
              </el-form-item>
              <div class="row date-item pc">
                <el-form-item label="Arrival Date" prop="arrival_date" :rules="rules.required">
                  <el-date-picker style="width: 100%" :disabled="data.status && data.status != 'Draft'&&data.is_block_date" :picker-options="pickerOptions0" v-model="data.arrival_date" :default-value="mouthdate.start_date"  type="date" format="MM/dd/yyyy" value-format="yyyy-MM-dd" >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Departure Date" prop="departure_date" :rules="rules.required">
                  <el-date-picker style="width: 100%" :disabled="data.status && data.status != 'Draft'&&data.is_block_date" :picker-options="pickerOptions1" v-model="data.departure_date" :default-value="mouthdate.end_date"  type="date" format="MM/dd/yyyy" value-format="yyyy-MM-dd" >
                  </el-date-picker>
                </el-form-item>
              </div>
              <!-- <el-form-item prop="dates" label="Date" class="pc">
                <el-date-picker style="width: 100%" v-model="data.dates" type="daterange" range-separator="—" start-placeholder="Arrival Date" end-placeholder="Departure Date"
                  :picker-options="expireTimeOption" format="MM/dd/yyyy" value-format="yyyy-MM-dd" :disabled="data.status && data.status != 'Draft'&&data.is_block_date">
                </el-date-picker>
              </el-form-item> -->
              <div class="row date-item pc">
                <el-form-item label="Check-in Time" prop="checkin_time" :rules="rules.required">
                  <el-time-picker value-format="HH:mm:ss" format="h:mm:A" v-model="data.checkin_time" placeholder="" :disabled="data.status && data.status != 'Draft'">
                  </el-time-picker>
                </el-form-item>
                <el-form-item label="Check-out Time" prop="checkout_time" :rules="rules.required">
                  <el-time-picker value-format="HH:mm:ss" format="h:mm:A" v-model="data.checkout_time" placeholder="" :disabled="data.status && data.status != 'Draft'">
                  </el-time-picker>
                </el-form-item>
              </div>
              <div class="form-vertical">
                <el-form-item label="Rent information">
                  <el-checkbox v-model="data.tax_exempt" :disabled="data.status && data.status != 'Draft'" >Lease is Tax Exempt</el-checkbox>
                </el-form-item>
                <el-form-item label="Upload file" class="form-item-upload" v-if="data.tax_exempt" >
                  <div class="form-item-box row">
                    <el-upload class="upload-demo" action :http-request="uploadFile" :show-file-list="false" :disabled="data.status && data.status != 'Draft'">
                      <el-button slot="trigger" size="small">
                        <img src="../../assets/icon/icon-upload.svg" alt />
                      </el-button>
                    </el-upload>
                    <span v-if="data.tax_exempt_file_name" style="color: #222" >{{ data.tax_exempt_file_name }}</span >
                    <span v-else style="color: #678993">Upload Tax Exemption Form</span >
                  </div>
                </el-form-item>
                <el-form-item label="Commission" prop="commission_percentage" :rules="rules.positiveNumber" >
                  <el-input type="number" v-model="data.commission_percentage" @input="limitNumber" :disabled="data.status && data.status != 'Draft'" >
                    <strong slot="prefix">%</strong>
                  </el-input>
                </el-form-item>
                <el-form-item label="Rent" prop="rent" :rules="rules.positiveNumber" >
                  <el-input type="number" :min="0" oninput="if(value<0)value=0" v-model="data.rent" :disabled="data.status && data.status != 'Draft'" >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
                <el-form-item label="Processing Fee" prop="processing_fee" :rules="rules.processingNumberValidate" >
                  <el-input type="number" :min="0" oninput="if(value<0)value=0" v-model="data.processing_fee" :disabled="data.status && data.status != 'Draft'||data.is_co_broke_lease&&data.represent==='tenant'" >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
                <el-form-item label="Occupancy Tax">
                  <label>$ {{ data.tax | currencyPrice }}</label>
                </el-form-item>
                <el-form-item label="Security Deposit">
                  <!-- 如果是co_broke，Processing Fee和Security Deposit全为0 -->
                  <el-input  type="number" v-model="data.security_deposit_amount"  :disabled="data.status && data.status != 'Draft'||data.is_co_broke_lease&&data.represent==='tenant'" :min="0" oninput="if(value<0)value=0" >
                    <strong slot="prefix">$</strong>
                  </el-input>
                </el-form-item>
                <el-form-item label="Other Fees" class="other_fee">
                  <label>$ {{ data.other_fee | currencyPrice }}</label>
                  <p class="manage-other-fees" @click="openOtherFeeDialog">
                    <a>View/Edit Fees</a>
                  </p>
                </el-form-item>
                <el-form-item label="Grand Total" class="total">
                  <label>$ {{ data.total_amount | currencyPrice }}</label>
                </el-form-item>
              </div>
            </div>
        
          </div>
          <!-- other fee弹出框 -->
          <el-dialog :visible.sync="showOtherFeeDialog" class="other-dialog" title="Add Other Fees"  >
            <div class="other-fee-dialog">
              <div v-for="(item, index) in data.other_fees" :key="index" style="margin-bottom: 10px" >
                <div class="other-fee-detail">
                  <el-form-item label="Fee" :prop="'other_fees.' + index + '.amount'" :rules="rules.positiveNumber" class="fee" >
                    <el-input type="number" v-model="item.amount" :disabled="data.status && data.status != 'Draft'" >
                      <strong slot="prefix">$</strong>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Reason" :prop="'other_fees.' + index + '.reason'" :rules="rules.required" class="reason" >
                    <el-input v-model="item.reason" :disabled="data.status && data.status != 'Draft'" ></el-input>
                  </el-form-item>
                  <span @click="data.other_fees.splice(index, 1)" class="other-fee-delete" >
                    <i class="el-icon-delete total_close"></i>
                  </span>
                </div>
                <div class="other-fee-check">
                  <div class="other-fee-taxable">
                    <el-checkbox v-model="item.taxable" :disabled="data.status && data.status != 'Draft'" >Taxable</el-checkbox >
                  </div>
                </div>
              </div>
              <p class="other-fee-add">
                <el-button @click="data.other_fees.push({})" :disabled="data.status && data.status != 'Draft'" >
                  <a> + Add other fees</a>
                </el-button>
              </p>
            </div>
            <p style="text-align: right">
              <el-button class="primary-button" type="primary" :disabled="data.status && data.status != 'Draft'" @click="confirmOtherFee" >Add</el-button>
            </p>
          </el-dialog>
        </el-form>
      </div>
      <!-- 管理员选择代理人 -->
      <Agent-Dialog ref="showAgentDialog" @select="selectAgent"></Agent-Dialog>
      <!-- 选择房屋的弹出框 -->
      <Properties-Dialog ref="propertiesDialog" @select="selectProperty"></Properties-Dialog>
      <!-- 选择租客 -->
      <contact-Dialog ref="tenantDialog"  @select="selectTenant" :contacts="contacts"></contact-Dialog>
    </div>
    <div class="btn-sumit">
      <el-button class="primary-button" @click="save" :disabled="data.status && data.status != 'Draft'"><p class="row"><span>Confirm</span><i class="el-icon-right"></i></p ></el-button>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import PropertiesDialog from "../common/PropertiesDialog";
import ContactDialog from "../common/ContactDialog";
import AgentDialog from "../common/AgentDialog";
import CancelDialog from "./Component/LeaseCancelDialog";
import Broke from "./Component/LeaseBroke";
import { mapActions, mapState } from "vuex";
import {apiGetListing, apiCalculateRent,apiGetLease,apiCreateLease,apiUpdateLease,apiUploadFile,} from "../../API/api";
const STATE_TAX = 0.057;
const LOCAL_TAX = 0.06;
const COMMUNITY_IMPACT_TAX = 0.03;
var no_smoking = "No Smoking - No Smoking is allowed on the premises.";
var peks =
  "Pets Allowed - Notwithstanding the foregoing, Landlord agrees to allow tenant to have one dog on the property during tenancy with the understanding that tenant shall be solely responsible for any dog-related damage at the end of lease term. Amount of dog related damage shall not be limited to the amount of the security deposit. Tenant agrees to abide by the Nantucket Leash and Pick Up Laws.";
var pool_waiver =
  "Pool Waiver- Tenant is required to sign the attached Pool/Spa waiver.";
var show_clause =
  "Show Clause- Tenant agrees to allow property to be shown to prospective buyers with 24 hour notice from listing broker.";
var roofwalk_waiver =
  "Roofwalk Waiver- Tenant is required to sign the attached Roofwalk Waiver.";
function keepTwoDecimal(num) {
  return Math.round(parseFloat(num) * 100) / 100; // 采用四舍五入，保存两位小数
}
function calcTotalPrice(cost) {
  return keepTwoDecimal(
    Object.values(cost).reduce((previous, current) => {
      return previous + parseFloat(current);
    }, 0)
  );
}
function getSum(num1, num2) {
  //定义自定义函数getSum
  let sum = Number(num1) + Number(num2);
  return sum; //返回num1和num2的和
}
export default {
  props:[ "newDatas"],
  data() {
    let positiveNumberValidate = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("Please enter a number greater than or equal to zero")
        ); } else {
        callback();
      }
    };
    let processingNumberValidate = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error("Please enter a number greater than zero"));
      } else {
        callback();
      }
    };
    return {
      flag: this.$route.params.id ? false : true, // 首次使用接口返回的数据展示，修改过后使用计算属性来展示
      fullscreenLoading: false,
      showOtherFeeDialog: false, // 显示其它费用对话框
      clauses: ["No Smoking","Pets OK","Pool Waiver Required","Show Clause","Roof Walk Waiver Required", ],
      listingInfo: {}, // 存放和房屋可租性还有保证金相关的信息(保证金百分比，最低保证金)
      data: {
        dates: [],
        co_broke_agency: "",
        is_co_broke_lease: false,
        represent: "tenant",
        tax: 0,
        tax_exempt: false,
        rent: 0,
        processing_fee: 100,
        security_deposit_amount:0,
        checkin_time: "15:00:00",
        checkout_time: "10:00:00",
        commission_percentage: 15,
        other_fee: 0,
        other_fees: [],
        total_amount: 100,
        clauses: [],
        comment: "",
      },
      rules: {
        required: [{ required: true, message: "this information is required",  trigger: "blur" }],
        positiveNumber: [
          { required: true, message: "this information is required", trigger: "blur"  },
          { validator: positiveNumberValidate, trigger: "blur" },
        ],
        processingNumberValidate: [
          { required: true, message: "this information is required", trigger: "blur", },
          { validator: processingNumberValidate, trigger: "blur" },
        ],
      },
      contacts: ["tenant"],
      newData:{},
      mouthdate:{start_date:"",end_date:""}
    };
  },
  computed: mapState({
    companyList: (state) => state.enum.companyList,
     pickerOptions0(){
       return {
          disabledDate: (date) => {
          date = dayjs(dayjs(date).format("YYYY-MM-DD"));
          if (this.$store.state.userInfo.is_admin != true&&date < dayjs(dayjs().format("YYYY-MM-DD"))) {
            this.mouthdate.start_date = dayjs(this.data.departure_date).format("YYYY/MM")+"/01"
            return true;
          }
          if(this.data.departure_date&&date > dayjs(dayjs(this.data.departure_date).format("YYYY-MM-DD"))){
            this.mouthdate.end_date = dayjs(this.data.arrival_date).format("YYYY/MM")+"/01";
            return true;
          }
          if (this.listingInfo.availabilities) {
            this.mouthdate.start_date = dayjs(this.data.departure_date).format("YYYY/MM")+"/01"
            let availabilities = this.listingInfo.availabilities;
            for (let i = 0; i < availabilities.length; i++) {
              if ( date > dayjs(availabilities[i].from_date) && date < dayjs(availabilities[i].to_date)) {
                return true;
              } else if ( i + 1 !== availabilities.length && date.format("YYYY-MM-DD") === dayjs(availabilities[i].to_date).format("YYYY-MM-DD") && date.format("YYYY-MM-DD") === dayjs(availabilities[i + 1].from_date).format("YYYY-MM-DD")
              ) {
                return true;
              }
            }
            return false;
          }
          return false;
        },
      }
    },
    pickerOptions1() {
      return {
          disabledDate: (date) => {
          date = dayjs(dayjs(date).format("YYYY-MM-DD"));
          if (this.$store.state.userInfo.is_admin != true&&date < dayjs(dayjs().format("YYYY-MM-DD"))) {
            this.mouthdate.end_date = dayjs(this.data.arrival_date).format("YYYY/MM")+"/01";
            return true;
          }
          if(this.data.arrival_date&&date < dayjs(dayjs(this.data.arrival_date).format("YYYY-MM-DD"))){
            this.mouthdate.end_date = dayjs(this.data.arrival_date).format("YYYY/MM")+"/01";
            return true;
          }
          if (this.listingInfo.availabilities) {
            this.mouthdate.end_date = dayjs(this.data.arrival_date).format("YYYY/MM")+"/01";
            let availabilities = this.listingInfo.availabilities;
            for (let i = 0; i < availabilities.length; i++) {
              if ( date > dayjs(availabilities[i].from_date) && date < dayjs(availabilities[i].to_date)) {
                return true;
              } else if ( i + 1 !== availabilities.length && date.format("YYYY-MM-DD") === dayjs(availabilities[i].to_date).format("YYYY-MM-DD") && date.format("YYYY-MM-DD") === dayjs(availabilities[i + 1].from_date).format("YYYY-MM-DD") ) {
                return true;
              }
            }
            return false;
          }
          return false;
        },
      }
    },
    expireTimeOption() {
      return {
        disabledDate: (date) => {
          date = dayjs(dayjs(date).format("YYYY-MM-DD"));
          if (date < dayjs(dayjs().format("YYYY-MM-DD"))) {
            return true;
          }
          // if (this.data.is_co_broke_lease && this.data.represent === "tenant") {
          //   return false
          // } else {
          if (this.listingInfo.availabilities) {
            let availabilities = this.listingInfo.availabilities;
            for (let i = 0; i < availabilities.length; i++) {
              if ( date > dayjs(availabilities[i].from_date) && date < dayjs(availabilities[i].to_date)) {
                return true;
              } else if (
                i + 1 !== availabilities.length &&
                date.format("YYYY-MM-DD") ===
                  dayjs(availabilities[i].to_date).format("YYYY-MM-DD") &&
                date.format("YYYY-MM-DD") ===
                  dayjs(availabilities[i + 1].from_date).format("YYYY-MM-DD")
              ) {
                return true;
              }
            }
            return false;
          }
          // }
          return false;
        },
      };
    },
    payments() {
      // if( this.data.is_co_broke_lease&&this.data.represent==='tenant')
      if ( this.data.arrival_date && this.data.departure_date && dayjs(this.data.arrival_date) < dayjs(this.data.departure_date) ) {
        let now = dayjs(dayjs().format("YYYY-MM-DD"));
        let arrival_date = dayjs(this.data.arrival_date);
        /* First payment = lease creation date + 7 days
         Final payment = 45 days before arrival date
         Second and Third payment (if not the final payment) equal distance between the First and Final payments
         When the lease is created fewer than 45 days from arrival, there can only be 1 payment and
         ALL money is due 7 days after lease creation.
         Or if lease is created fewer than 7 days before arrival, payment is due on the day of lease creation.
         租约创建时间晚于到达日期前45天分一期，否则默认分两期
        第一期逾期时间在创建租约的后7天，第二期逾期时间在到达日期前45天
         此时有可能会出现第二期的逾期时间早于第一期的逾期时间，当出现这种情况时，也只能分一期
         arrival_date -45：第二期逾期时间              now + 7：第一期逾期时间*/
        if (arrival_date.add(-45, "day") <= now.add(7, "day")|| this.data.is_co_broke_lease&&this.data.represent==='tenant') {
          // 只有一期
          let due_date = "";
          if (arrival_date.add(-7) <= now) {
            // 到达日期再一周之内，当天就要支付第一期费用
            due_date = now; // 当天逾期
          } else {
            // 到达日期再一周之后，支付第一期费用需要在7天后
            due_date = now.add(7, "day"); // 逾期时间为：租约创建的日期加7天
          }
          return [
            {
              period: 1,
              due_date: due_date.format("YYYY-MM-DD"),
              total_amount: this.data.total_amount,
              bill: {
                rent: this.data.rent,
                processing_fee: this.data.processing_fee,
                tax: this.data.tax,
                security_deposit: this.data.security_deposit_amount,
                other_fee: this.data.other_fee,
              },
            },
          ];
        } else {
          // 默认两期
          let cost1 = {
            rent: keepTwoDecimal(this.data.rent / 2),
            processing_fee: this.data.processing_fee,
            tax: this.data.tax,
          };
          let cost2 = {
            rent: keepTwoDecimal(
              this.data.rent - keepTwoDecimal(this.data.rent / 2)
            ),
            security_deposit: this.data.security_deposit_amount,
            other_fee: this.data.other_fee,
          };
          return [
            {
              // 手续费、国家税、地方税第一期支付
              period: 1,
              due_date: now.add(7, "day").format("YYYY-MM-DD"), // 第一期的逾期时间是创建租约后的7天
              total_amount: calcTotalPrice(cost1),
              bill: cost1,
            },
            {
              // 保证金、其它费用在最后一期支付
              period: 2,
              due_date: arrival_date.add(-45, "day").format("YYYY-MM-DD"), // 最后一期的逾期时间是到达日期的前45天
              total_amount: calcTotalPrice(cost2),
              bill: cost2,
            },
          ];
        }
      }
    },
    occupancy_taxAll() {
      let stateTax = 0;
      let localTax = 0;
      if(this.data.is_co_broke_lease&&this.data.represent==='tenant'){
         return 0;
      }else{
      if (this.data || this.data.status === "Draft") {
          if (!this.data.tax_exempt) {
            // 不免税
            let sum = Number(this.data.rent) + Number(this.data.processing_fee);
            stateTax += sum * STATE_TAX;
            // let sumT = Number(this.data.rent) + Number(this.data.processing_fee);
            localTax += sum * LOCAL_TAX;
            if (this.listingInfo.charge_community_impact_fee) {
              stateTax += sum * COMMUNITY_IMPACT_TAX;
            }
          }
          if (this.data.other_fees) {
            // 加上其它费用的国家税
            stateTax += this.data.other_fees.reduce((previous, current) => {
              if (current.amount && current.taxable&&!this.data.tax_exempt) {
                if (this.listingInfo.charge_community_impact_fee) {
                  return previous + current.amount * (STATE_TAX + COMMUNITY_IMPACT_TAX);
                }
                return previous + current.amount * STATE_TAX;
              } else {
                return previous;
              }
            }, 0);
            localTax += this.data.other_fees.reduce((previous, current) => {
              if (current.amount && current.taxable&&!this.data.tax_exempt) {
                return previous + current.amount * LOCAL_TAX;
              } else {
                return previous;
              }
            }, 0);
          }
        
        return keepTwoDecimal(parseFloat(stateTax) + parseFloat(localTax));
      } else if (this.data.status != "Draft") {
        return this.data.tax;
      }}
    },
    security_deposit_amount_change() {
      if(this.data.is_co_broke_lease&&this.data.represent==='tenant'){
         return 0;
      }else{
      let securityDepositAmount = 0;
      if (this.listingInfo.security_deposit_percentage) {
        securityDepositAmount +=
          this.data.rent * this.listingInfo.security_deposit_percentage;
      } else {
        securityDepositAmount += this.data.rent * 0.1;
      }
      if (
        this.listingInfo.min_security_deposit &&
        this.listingInfo.min_security_deposit > securityDepositAmount
      ) {
        securityDepositAmount = this.listingInfo.min_security_deposit;
      }
      return keepTwoDecimal(securityDepositAmount);
      }
    },
    other_fee() {
      if(this.data.is_co_broke_lease&&this.data.represent==='tenant'){
         return 0;
      }else{
      if (this.data.other_fees) {
        return keepTwoDecimal(
          this.data.other_fees.reduce((previous, current) => {
            if (current.amount) {
              return previous + parseFloat(current.amount);
            } else {
              return previous;
            }
          }, 0)
        );
      }
      return 0;
      }
    },
    total_amount() {
      if ( this.data.security_deposit_amount) {
        return keepTwoDecimal(
          parseFloat(this.data.rent) +
            parseFloat(this.data.processing_fee) +
            parseFloat(this.data.tax) +
            parseFloat(this.data.security_deposit_amount) +
            parseFloat(this.data.other_fee)
        );
      }
      {
        return keepTwoDecimal(
          parseFloat(this.data.rent) +
            parseFloat(this.data.processing_fee) +
            parseFloat(this.data.tax) +
            parseFloat(this.data.other_fee)
        );
      }
    },
  }),
  watch: {
    newDatas(val){
      this.changeGetLease(val);
      setTimeout(() => {
       this.$store.commit("setLeaseDetail", false);
       this.$store.commit("setLeaseAgeent", false);
       this.$store.commit("setLeaseParam", {});
        this.$store.commit("setLeaseSecurityDeposit", false);
      }, 100);
       
    },
    "data.dates"(newV, oldV) {
      if (newV != undefined && newV.length != 0) {
        if (oldV === null || oldV[0] != newV[0] || oldV[1] != newV[1]) {
          this.data.arrival_date = newV[0];
          this.data.departure_date = newV[1];
          this.changeTax();
          if (this.flag) {
            // 获取租金价格
            this.getRent();
          }
        }
      }
    },
    "data.arrival_date"(){
      this.changeTax();
      if(this.data.departure_date&&this.data.arrival_date){
        if (this.flag) {
          this.getRent();
        }
      }
    },
    "data.departure_date"(){
      this.changeTax();
      if(this.data.departure_date&&this.data.arrival_date){
        if (this.flag) {
          this.getRent();
        }
      }
    },
    occupancy_taxAll() {
        this.changeTax()
    },
    security_deposit_amount_change(newValue) {
      if (this.flag) {
        this.data.security_deposit_amount = newValue;
      }
    },
    other_fee(newValue) {
      if (this.flag) {
        this.data.other_fee = newValue;
      }
    },
    total_amount(newValue) {
      if (this.flag) {
        this.data.total_amount = newValue;
      }
    },
    "data.listing"(newValue) {
      if(this.data.status == 'Draft'||!this.data.status||this.data.status === undefined ){
          // 获取该房屋保证金相关的信息
          apiGetListing(newValue, { short: true }).then((res) => {
            this.listingInfo = res;
            this.data.checkin_time = res.checkin_time;
            this.data.checkout_time = res.checkout_time;
            this.data.commission_percentage = res.commission_percentage * 100 ;
          }).catch(()=>{
            this.data.checkin_time = "15:00:00";
            this.data.checkout_time = "10:00:00";
            this.data.commission_percentage = 15;
          });
          if (this.flag) {
            // 获取租金价格
            this.getRent();
          }
      }
    },
    "data.represent"(){
      if(this.data.is_co_broke_lease&&this.data.represent==='tenant'){
         this.data.processing_fee = 0;
      }
    },
    "data.is_co_broke_lease"(){
      if(this.data.is_co_broke_lease&&this.data.represent==='tenant'){
         this.data.processing_fee = 0;
      }
    }
  },
  components: {Broke,PropertiesDialog,ContactDialog,CancelDialog,AgentDialog,},
  methods: {
    ...mapActions(["getCompanyList"]),
    openOtherFeeDialog(){
      if(this.data.is_co_broke_lease&&this.data.represent==='tenant'){
      }else{
        this.showOtherFeeDialog = true;
      }
    },
    changeTax(){//超过32天免收税
    if(this.flag){
      if (this.data.departure_date&&this.data.arrival_date&&dayjs(this.data.departure_date).diff(dayjs(this.data.arrival_date),"day") >= 32) {
         this.data.tax = 0.00;
        } else {
          this.data.tax = this.occupancy_taxAll;
        }}
    },
    limitNumber(val) {
      if (val > 100) {
        this.data.commission_percentage = 100;
      } else if (val < 0) {
        this.data.commission_percentage = 0;
      }
    },
    clausesChange(val) { // 评论
      this.data.comment = "";
      let no_smoking_show = val.findIndex((item) => {
        return item == "No Smoking";
      });
      this.data.comment += no_smoking_show != -1 ? no_smoking + "\r\n\r\n" : "";
      let pets_show = val.findIndex((item) => {
        return item == "Pets OK";
      });
      this.data.comment += pets_show != -1 ? peks + "\r\n\r\n" : "";
      let pool_waiver_show = val.findIndex((item) => {
        return item == "Pool Waiver Required";
      });
      this.data.comment += pool_waiver_show != -1 ? pool_waiver + "\r\n\r\n" : "";
      let show_clause_show = val.findIndex((item) => {
        return item == "Show Clause";
      });
      this.data.comment +=show_clause_show != -1 ? show_clause + "\r\n\r\n" : "";
      let roofwalk_waiver_show = val.findIndex((item) => {
        return item == "Roof Walk Waiver Required";
      });
      this.data.comment += roofwalk_waiver_show != -1 ? roofwalk_waiver + "\r\n\r\n" : "";
    },
    // 选择代理人
    selectAgent(item) {
      this.$set(this.data, "user", item.user_id);
      this.$set(this.data,"agent_name",item.first_name + " " + item.last_name);
      this.$refs.showAgentDialog.show = false;
      this.$refs.user.clearValidate(); // 选中后清除校验，不加这行会提示错误消息
    },
    // 选择房屋
    selectProperty(item) {
      this.$set(this.data, "listing", item.listing_id);
      this.$set(this.data, "listing_address", item.address);
      this.$refs.propertiesDialog.show = false;
      this.$refs.propertyAddress.clearValidate(); // 选中后清除校验，不加这行会提示错误消息
    },
    // 选择租客
    openContactDialog() {
      if (this.data.status && this.data.status != "Draft") {
        this.$refs.tenantDialog.show = false;
      } else {
        this.$refs.tenantDialog.getContacts();
        this.$refs.tenantDialog.show = true;
      }
    },
    selectTenant(item) {
      this.$set(this.data, "tenant", item.contact_id);
      this.$set( this.data,"tenant_name",item.first_name + " " + item.last_name);
      this.$refs.tenantDialog.show = false;
      this.$refs.tenantName.clearValidate(); // 选中后清除校验，不加这行会提示错误消息
    },
    // 上传文件
    uploadFile(event) {
      let form = new FormData();
      form.append("file", event.file);
      apiUploadFile(form).then((res) => {
          this.data.tax_exempt_file = res.object_uuid;
          this.$set(this.data, "tax_exempt_file_name", res.file_name);
        }).catch((err) => {
          this.$message.error("Upload failure");
        });
    },
    getRent() {// 通过接口获取租金价格
      if (this.data.listing &&this.data.arrival_date &&this.data.departure_date) {
        apiCalculateRent({
          listing: this.data.listing,
          arrival_date: this.data.arrival_date,
          departure_date: this.data.departure_date,
        }).then((res) => {
            this.data.rent = res.rent;
          }).catch((err) => {
            this.$alert("The rent cannot be calculated for you automatically." +err.response.data.detail?err.response.data.detail:null,{ confirmButtonText: "OK" } );
          });
      }
    },
    confirmOtherFee() { // 其它费用
      let validSuccess = true;
      this.data.other_fees.forEach((item, index) => {
        this.$refs.leaseForm.validateField("other_fees." + index + ".name",
          (valid) => {
            if (valid) {
              validSuccess = false;
            }
          }
        );
        this.$refs.leaseForm.validateField("other_fees." + index + ".amount",
          (valid) => {
            if (valid) {
              validSuccess = false;
            }
          }
        );
      });
      if (validSuccess) {
        this.showOtherFeeDialog = false;
      }
    },
    changeData(){
      if(this.$route.params.id){
         let totalRent = keepTwoDecimal((this.data.payments).reduce((previous, current) => {return previous + (current.bill["rent"] - 0); }, 0));
        let allLength = this.data.payments.length;
        if (totalRent != this.data.rent) {
           this.data.payments = this.payments;
           
        } else if (this.data.processing_fee !=this.data.payments[0].bill["processing_fee"]) {
           this.data.payments[0].bill["processing_fee"] = this.data.processing_fee;
            this.data.payments[0].bill["tax"] = this.data.tax;
           this.data.payments[0].total_amount = calcTotalPrice( this.data.payments[0].bill);
        }else if (this.data.tax !=this.data.payments[0].bill["tax"]) {
           this.data.payments[0].bill["tax"] = this.data.tax;
           this.data.payments[0].total_amount = calcTotalPrice( this.data.payments[0].bill);
        } else if (this.data.other_fee !=this.data.payments[allLength - 1].bill["other_fee"]) 
        {   
            this.data.payments[allLength - 1].bill[ "other_fee" ] = this.data.other_fee;
            this.data.payments[allLength - 1].total_amount = calcTotalPrice(this.data.payments[allLength - 1].bill);
            this.data.payments[0].bill["tax"] = this.data.tax;
            this.data.payments[0].total_amount = calcTotalPrice( this.data.payments[0].bill );
        } else if (this.data.security_deposit_amount !=this.data.payments[allLength - 1].bill["security_deposit"])
         {
            this.data.payments[allLength - 1].bill["security_deposit"] = this.data.security_deposit_amount;
            this.data.payments[allLength - 1].total_amount = calcTotalPrice( this.data.payments[allLength - 1].bill);
         } 
      }
      this.newData = Object.assign({}, this.data);
      delete this.newData.dates;
      this.newData.commission_percentage = this.newData.commission_percentage / 100;
    },
    // 保存或修改
    save() {
      this.$refs.leaseForm.validate((valid, invalidFields) => {
        if (valid) {
          if (this.data.s_co_broker_lease === false) {
            this.data.represent = "tenant";
            this.data.co_broke_agency = "";
          } else if (this.data.is_co_broke_lease &&this.data.represent === "tenant")
           {
            this.data.listing = null;
          }
          if(!this.data.tax_exempt) delete this.data.tax_exempt_file
           this.fullscreenLoading = true;
          if (this.$route.params.id) {
            // 修改
            this.changeData()
            apiUpdateLease(this.$route.params.id, this.newData)
              .then((res) => {
                 let text = "Updated lease successful";
                 this.afterSave(res,text)
              }).catch((err) => {
                this.$alert(err.response.data.detail, {confirmButtonText: "OK",});
              }).finally(() => {
                this.fullscreenLoading = false;
              });
          } else {
            // 创建
            this.data["payments"] = this.payments;
            this.changeData()
            apiCreateLease(this.newData).then((res) => {
              let text = "Created lease successful";
              this.afterSave(res,text)
              }).catch((err) => {
                this.$alert(err.response.data.detail, {confirmButtonText: "OK"});
              }).finally(() => {
                this.fullscreenLoading = false;
              });
          }
        } else {
          let showOtherFeeDialog = Object.keys(invalidFields).some(
            (invalidField) => {return invalidField.includes("other_fees");}
          );
          if (showOtherFeeDialog) {this.showOtherFeeDialog = true;}
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
        }
      });
    },
    afterSave(res,text){
      this.$store.commit("setLease", JSON.parse(JSON.stringify(res)));
      this.$store.commit("setLeaseDetail", true);
      this.data = res;
      this.$message.success(text);
      this.$router.push({name: "lease-payment", params: { id: res.lease_id },});
    },
    // 获取租约详情
    getLease() {
      apiGetLease(this.$route.params.id).then((res) => {
        this.changeGetLease(res)
      });
    },
    changeGetLease(res){
      this.$store.commit("setLease", JSON.parse(JSON.stringify(res)));
        this.$store.commit("setLeaseDetail", true);
        if (res.sign_info || this.data.sign_file_info) {
          this.$store.commit("setLeaseAgeent", true);
        }
        if (res.security_deposit != null &&res.security_deposit.status === "Paid")
         {
          this.$store.commit("setLeaseSecurityDeposit", true);
        }
        let obj = { dates: [], tenant_name: "", agent_name: "" };
        obj.dates.push(res.arrival_date);
        obj.dates.push(res.departure_date);
        obj.tenant_name = res.tenant_first_name + " " + res.tenant_last_name;
        obj.agent_name = res.user_first_name + " " + res.user_last_name;
        this.data = res;
        this.data = Object.assign({}, obj, this.data);
        this.data.processing_fee = res.processing_fee
        this.data.tax = res.tax;
        this.data.commission_percentage = this.data.commission_percentage * 100;
        setTimeout(() => {
          this.flag = true;
        }, 100);
    }
  },
  created() {
    if (this.companyList.length === 0) {
      this.getCompanyList();
    }
    if (this.$route.params.id) {
      // if (this.$store.state.lease.lease_id === this.$route.params.id - 0) {
      //   this.data = JSON.parse(JSON.stringify(this.$store.state.lease));
      //   setTimeout(() => {
      //     this.flag = true;
      //   }, 100);
      // } else {
      this.getLease();
      // }
    } else if (this.$store.state.leaseParam) {
      this.$set(this.data, "listing", this.$store.state.leaseParam.listing);
      this.$set( this.data, "listing_address", this.$store.state.leaseParam.listingAddress );
      this.$set(this.data, "tenant", this.$store.state.leaseParam.tenant);
      this.$set( this.data, "tenant_name", this.$store.state.leaseParam.tenantName );
      if (this.$store.state.leaseParam.arrivalDate) {
        this.$set( this.data, "arrival_date", this.$store.state.leaseParam.arrivalDate );
        this.$set( this.data, "departure_date", this.$store.state.leaseParam.departureDate );
        this.data.dates.push(this.$store.state.leaseParam.arrivalDate);
        this.data.dates.push(this.$store.state.leaseParam.departureDate);
      }
      this.$store.commit("setLeaseParam", {});
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./LeaseDetails.scss";
</style>

<template>
  <div class="setPage">
    <el-pagination v-if="total" layout="prev, pager, next" :page-size="pageSize" :total="total" :current-page="currentPage" @current-change="curr">
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: ["total", "pageSize", "currentPage"],
  methods: {
    curr(val) {
      this.$emit("number", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.setPage {
    text-align: left;
    margin-top: 10px;
  /deep/ .el-pager li.active {
    color: #678993;
    font-size: 14px;
    font-family: "Roboto-Bold", sans-serif;
  }
 
}
</style>
<template>
<div class="box">
  <el-dialog title="Select the Contact" :visible.sync="show">
    <div class="rental_listing">
      <div class="search_rent">
        <el-input
          v-model="search"
          placeholder="Search by name,email"
        ></el-input>
        <el-button
          class="primary-button"
          icon="el-icon-search"
          @click="searchFind"
        ></el-button>
      </div>
      <div class="table">
        <el-table
          v-loading="loading"
          :data="data"
          style="width: 100%"
          element-loading-text="Loading..."
          @cell-click="select"
        >
          <el-table-column label="Name">
            <template slot-scope="scope">
              {{ scope.row.first_name }} {{ scope.row.last_name }}
            </template>
          </el-table-column>
          <el-table-column prop="email1" label="Email"></el-table-column>
        </el-table>
      </div>
      <Page :total="totalPage" :pageSize="10" @number="pagesChanged"></Page>
    </div>
  </el-dialog>
</div>
  
</template>

<script>
import { apiGetContacts } from "../../API/api";
import Page from "./Paging";
export default {
  name: "TenantDialog",
  props: ["contacts"],
  components: { Page },
  data() {
    return {
      show: false, // 是否显示对话框
      loading: false,
      totalPage: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 10, // 每页显示数量
      search: "",
      oldsearch: "",
      firstName: "",
      lastName: "",
      oldFirstName: "",
      oldLastName: "",
      data: [], // 房屋列表
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      // 房屋过滤查询参数
      return {
        type: this.contacts,
        limit: this.limit,
        offset: this.offset,
        self:
          (this.$store.state.userInfo.is_admin === true)&& this.$store.state.userInfo.login_email != "stephen@nantucketbrokers.com"
            ? false
            : true,
        search: this.oldsearch,
        // first_name: this.oldFirstName,
        // last_name: this.oldLastName,
      };
    },
  },
  methods: {
    // 获取房屋列表
    getContacts() {
      this.loading = true;
      apiGetContacts(this.query)
        .then((res) => {
          this.data = res.results;
          this.totalPage = Math.ceil(res.count / this.limit) || 1;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getContacts();
    },
    // 搜索过滤
    searchFind() {
      this.currentPage = 1;
      this.oldsearch = this.search;
      // this.oldLastName = this.lastName;
      this.getContacts();
    },
    //设置给租客指定房屋
    select(val) {
      this.$emit("select", val);
    },
  },
};
</script>

<style lang="scss" scoped>
/* properties 选择房屋弹出框 */
@import "../../css/button.scss";
@import "../../css/dialog.scss";
@import "../../css/table.scss";
.box{
  .search_rent {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 10px 0;
    .primary-button {
      margin-left: 20px;
    }
  }
  .rental_listing /deep/ .el-input__inner {
    height: 40px;
    line-height: normal;
  }
  .table /deep/ .el-table td {
    padding: 10px 0;
  }
   /deep/.el-dialog{
    width: 550px;
     }
  @media (max-width:992px) {
    /deep/.el-dialog{
     width: 90%;
     }
  }
}
</style>

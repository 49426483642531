<template>
  <div class="broke-box">
    <div class="row broke-row">
      <div class="broke-sheck">
        <el-checkbox v-model="checked" style="margin-bottom:10px">Co - Broke Lease</el-checkbox>
        <div>
          <el-radio-group
            class="slsect-radio"
            v-model="radio"
            v-if="checked === true"
          >
            <el-radio :label="3" style="margin-bottom:10px">Representing Owner</el-radio>
            <el-radio :label="6" style="margin-bottom:10px">Representing Tenant</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="broke-input" v-if="checked === true">
        <slot name="input"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: true,
      radio: 3,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.broke-box {
    margin-top: 20px;
  .broke-row {
    justify-content: space-between;
    align-items: flex-start;
    .broke-sheck {
      width: 240px;
      text-align: left;
      /deep/ .el-radio__input{
        width: 10px;
        height: 10px;
        background-color: #F4F9FC;
        .el-radio__inner{
          border-radius: 2px;
          background-color: #F4F9FC;
        }
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border: none;
        background: none;
        background-image: url("../../../assets/icon/ico-checkbox.svg");
      }
      /deep/ .el-radio__input.is-checked+.el-radio__label{
          color: #606266;
      }
      /deep/ .el-radio__inner::after {
        display: none;
      }
    }
    .broke-input {
      width: calc(100% - 260px);
    }
  }
}
</style>
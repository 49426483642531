<template>
  <div class="listing">
    <el-dialog title="Select the Rental Listing" :visible.sync="show">
      <div class="rental_listing">
        <slot name="add-listing"></slot>
        <div class="search_rent">
          <div class="rent_input">
            <el-input v-model="search" @keyup.enter.native="handleSearch" placeholder="Search by Owner or Address"></el-input>
          </div>
          <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        </div>
        <div class="table">
          <el-table
            v-loading="loading"
            :data="properties"
            style="width: 100%"
            element-loading-text="Loading..."
            @cell-click="select"
          >
            <el-table-column prop="owner_last_name" label="HOMEOWNER" width="200"></el-table-column>
            <el-table-column prop="name" label="ADDRESS">
              <template slot-scope="scope">
                <span
                  style="word-wrap: break-word;width: 100%;display: inline-block;"
                >{{scope.row.address}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Page :total="total" :pageSize="10" @number="pagesChanged"></Page>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiGetListings } from "../../API/api";
import Page from "./Paging"
export default {
  components:{ Page },
  data() {
    return {
      show: false, // 是否显示对话框
      loading: false,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 10, // 每页显示数量
      search: "",
      oldAddress: "",
      properties: [] // 房屋列表
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      // 房屋过滤查询参数
      return {
        limit: this.limit,
        offset: this.offset,
        search_by_owner_last_name_or_address: this.oldAddress
      };
    }
  },
  methods: {
    pagesChanged(number){
      this.currentPage = number;
      this.getPropertyList();
    },
    // 获取房屋列表
    getPropertyList() {
      this.loading = true;
      apiGetListings(this.query).then(res => {
          this.properties = res.results;
          this.total = res.count ;
        }).finally(() => {
          this.loading = false;
        });
    },
    // 搜索过滤
    handleSearch() {
      this.currentPage = 1;
      this.oldAddress = this.search;
      this.getPropertyList();
    },
    //设置给租客指定房屋
    select(val) {
      this.$emit("select", val);
    }
  },
  created() {
    this.getPropertyList();
  }
};
</script>

<style lang="scss" scoped>
/* properties 选择房屋弹出框 */
@import "../../css/dialog.scss";
@import "../../css/table.scss";
.listing {
  
  .rental_listing {
    margin-top: 20px;
    /deep/ .el-input__inner {
      height: 40px;
      line-height: normal;
    }
    .search_rent {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 20px;
      /deep/ .el-button {
        background-color: #678993;
        color: #fff;
        font-size: 20px;
        border: none;
      }
      .rent_input {
        text-align: left;
        width: 75%;
        margin: 10px 20px 0px 0;
      }
    }
    .table {
      /deep/ .el-table  {
       td{
         padding: 10px 0;
       }
      }
    }
  }
  /deep/ .el-dialog{
    width: 450px;
  }
  @media (max-width:992px) {
    /deep/ .el-dialog{
      width: 90%;
    }
  }
}
</style>

<template>
  <el-dialog title="Select the Agent" :visible.sync="show" width="450px">
    <div class="rental_listing">
      <div class="search_rent">
        <div class="rent_input">
          <p>First name</p>
          <el-input v-model="firstName"></el-input>
        </div>
        <div class="rent_input">
          <p>Last name</p>
          <el-input v-model="lastName"></el-input>
        </div>
        <el-button icon="el-icon-search" @click="search"></el-button>
      </div>
      <div class="list_table">
        <el-table
          v-loading="loading"
          :data="data"
          style="width: 100%"
          element-loading-text="Loading..."
          @cell-click="select"
        >
          <el-table-column prop="first_name" label="First name" width="200"></el-table-column>
          <el-table-column prop="last_name" label="Last name"></el-table-column>
        </el-table>
      </div>
      <Page :total="total" :pageSize="10" @number="pagesChanged"></Page>
    </div>
  </el-dialog>
</template>

<script>
import { apiGetUsers } from "../../API/api";
import Page from "./Paging"
export default {
  components:{ Page } ,
  data() {
    return {
      show: false, // 是否显示对话框
      loading: false,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 10, // 每页显示数量
      firstName: "",
      lastName: "",
      oldFirstName: "",
      oldLastName: "",
      data: [] // 房屋列表
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      // 房屋过滤查询参数
      return {
        limit: this.limit,
        offset: this.offset,
        first_name: this.oldFirstName,
        last_name: this.oldLastName,
        user_type: "pm",
      };
    }
  },
  methods: {
    // 获取房屋列表
    getUsers() {
      this.loading = true;
      apiGetUsers(this.query)
        .then(res => {
          this.data = res.results;
          this.total = res.count ;
        })
        .catch(err => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 上一页
    pagesChanged(number) {
      this.currentPage = number
      this.getUsers();
    },
    // 搜索过滤
    search() {
      this.currentPage = 1;
      this.oldFirstName = this.firstName;
      this.oldLastName = this.lastName;
      this.getUsers();
    },
    //设置给租客指定房屋
    select(val) {
      this.$emit("select", val);
    }
  },
  created() {
    this.getUsers();
  }
};
</script>

<style scoped>
/* properties 选择房屋弹出框 */
.search_rent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
}

.search_rent /deep/ .el-button {
  background-color: #57737b;
  color: #fff;
  font-size: 20px;
}

.newtenants /deep/ .el-dialog__title {
  color: #fff;
}

.rent_input {
  text-align: left;
  margin-right: 20px;
}

.rent_input p {
  margin-bottom: 10px;
}

.rental_listing /deep/ .el-input__inner {
  height: 40px;
  line-height: normal;
}

.list_table /deep/ .el-table th {
  background: #eff3f4 0% 0% no-repeat padding-box;
  /* border-radius: 4px; */
}

.list_table /deep/ .el-table th:first-child {
  border-radius: 4px 0 0 4px;
}

.list_table /deep/ .el-table th:last-child {
  border-radius: 0 4px 4px 0;
}

.rent_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  margin-top: 20px;
}

.rent_footer i:hover {
  cursor: pointer;
}
</style>
